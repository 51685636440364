import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from "../utils/provider/AuthProvider";

function CreateCategory() {
  const [categoryName, setCategoryName] = useState('');
  const [images, setImages] = useState([]);
  const [existingImage, setExistingImage] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { accessToken } = useAuth();

  useEffect(() => {
    if (id) {
      axios.get(`${process.env.REACT_APP_API_URL}/site_defs/category/${id}/`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setCategoryName(response.data.name);
        setExistingImage(response.data.image);
      })
      .catch(error => {
        toastr.error('Kategori bilgileri alınırken bir hata oluştu.');
      });
    }
  }, [id, accessToken]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files) {
      setImages(files);
    }
  };

  const handleNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', categoryName);
    images.forEach((file) => {
      formData.append('image', file);
    });

    try {
      if (id) {
        await axios.put(`${process.env.REACT_APP_API_URL}/site_defs/category/${id}/`, formData, {
          mode: 'no-cors',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
          }
        });
        toastr.success('Kategori başarıyla güncellendi!');
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/site_defs/category/`, formData, {
          mode: 'no-cors',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
          }
        });
        toastr.success('Kategori başarıyla eklendi!');
      }
      navigate('/');
    } catch (error) {
      toastr.error('Kategori işlemi sırasında bir hata oluştu.');
    }
  };

  return (
    <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center" style={{ marginTop: '80px' }}>
      <div className="row w-100">
        <div className="col-md-12 col-lg-6 container">
          <div className="card shadow-sm">
            <div className="card-body">
              <h3 className="card-title mb-4">{id ? 'Kategori Düzenle' : 'Kategori Ekle'}</h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="categoryName" className="form-label">Kategori Adı</label>
                  <input
                    type="text"
                    className="form-control"
                    id="categoryName"
                    value={categoryName}
                    onChange={handleNameChange}
                    placeholder="Kategori adını buraya girin"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="imageUpload" className="form-label">Resim Yükle</label>
                  <input type="file" className="form-control" id="imageUpload" accept="image/*" onChange={handleFileChange}/>

                  <div className="mt-3">
                    {images.length > 0 && images.map((file, index) => (
                      <img
                        key={index}
                        src={URL.createObjectURL(file)}
                        alt=""
                        className="img-fluid rounded"
                        style={{ objectFit: 'cover', maxHeight: '200px', marginRight: '10px' }}
                      />
                    ))}

                    {existingImage && images.length === 0 && (
                      <img
                        src={existingImage}
                        alt=""
                        className="img-fluid rounded"
                        style={{ objectFit: 'cover', maxHeight: '200px' }}
                      />
                    )}
                  </div>
                </div>

                <button type="submit" className="btn btn-primary" style={{ width: '100%' }}> {id ? 'Güncelle' : 'Kaydet'} </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateCategory;
