import React, { useState, useEffect } from 'react';
import logo from '../static/navbar-logo.png';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import '../static/css/Navbar.css';
import { useAuth } from "./provider/AuthProvider";
import axios from "axios";
import toastr from "toastr";

function Navbar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { isSuperuser } = useAuth();
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top" style={{ paddingTop: '0rem', paddingBottom: '0' }}>
        <div className="container" style={{ display: 'flex', justifyContent: 'center' }}>
          <a href={'/'}>
            <img className="navbar-brand" src={logo} alt="" width={300} height={150} />
          </a>
          {isSuperuser && (
            <button className="navbar-toggler" type="button" onClick={toggleSidebar} style={{ position: 'absolute', right: '1rem', display: "block" }}>
              <FaBars size={22} />
            </button>
          )}
        </div>
      </nav>

      {sidebarOpen && <Sidebar onClose={() => setSidebarOpen(false)} />}
    </>
  );
}

const Sidebar = ({ onClose }) => {
  const { isSuperuser, setIsSuperuser } = useAuth();
  const { accessToken } = useAuth();

  const handleClickOutside = (event) => {
    if (event.target.closest('.sidebar') === null) onClose();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/accounts/logout/`,{
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
          }
      });
      setIsSuperuser(false);
      toastr.success('Çıkış Başarılı');
    } catch (error) {
      toastr.error('Çıkış Yapılamadı');
    }
  };

  if (!isSuperuser) {
    return null;
  }

  return (
    <div className="sidebar">
      <button className="sidebar-close" onClick={onClose}> &times; </button>
      <nav style={{ marginTop: '50px' }}>
        <Link to="/create-update-homepage-image" className="btn">Anasayfa Fotoğraf Ekle</Link>
        <Link to="/create-update-category" className="btn">Kategori Ekle</Link>
        <Link to="/create-update-product" className="btn">Ürün Ekle</Link>
        <button onClick={handleLogout} className="btn">Çıkış Yap</button>
      </nav>
    </div>
  );
};

export default Navbar;
