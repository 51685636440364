import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toastr from "toastr";
import { useAuth } from "../utils/provider/AuthProvider";
import axios from "axios";

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setIsSuperuser } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      let response = await axios.post(`${process.env.REACT_APP_API_URL}/accounts/token/`, {
        username,
        password
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        const data = response.data;
        setAccessToken(data.access);
        setIsSuperuser(true);
        navigate('/');
        toastr.success(`Giriş Başarılı. Hoşgeldin ${username}`);
      } else {
        // Eğer cevap 200 değilse, hata mesajı ver.
        setError('Giriş başarısız');
        toastr.error('Giriş başarısız');
      }
    } catch (error) {
      setError('Bir hata oluştu.');
      toastr.error(`Bir Hata Oluştu: ${error.message}`);
    }
  };

  return (
    <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center login-container">
      <div className="row w-100">
        <div className="col-md-12 col-lg-6 container">
          <div className="card shadow-sm">
            <div className="card-body">
              <h3 className="card-title mb-4">Giriş Yap</h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Kullanıcı Adı"
                    required
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Şifre"
                    required
                  />
                </div>

                {error && <div className="alert alert-danger">{error}</div>}

                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{width: '100%'}}
                >
                  Giriş Yap
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;