import React from 'react';
import Carousel from '../components/utils/Carousel';
import CategoryCardList from '../components/CategoryCardList';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Helmet} from "react-helmet";

function HomePage() {
  return (
    <div>
        <Helmet>
            <title>Hisar Yapı Metal - Demir Doğrama</title>
            <meta name="description" content="Hisar Yapı Metal, demir doğrama ve yapı sektöründe lider firmadır."/>
            <link rel="icon" href="/title.png"/>
        </Helmet>
        <div>
            <Carousel/>
            <CategoryCardList />
      </div>
    </div>
  );
}

export default HomePage;
